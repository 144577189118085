import React, { useState } from "react";

// CSS
import "./related-pages-styles.scss";

const RelatedPages: React.FC<{ options: { [key: string]: string }, title?: string }> = ({ options, title }): JSX.Element => {
   const [showPages, setShowPages] = useState<boolean>(typeof window !== "undefined" ? window.innerWidth > 1000 : false)

   const generateOptions = (): JSX.Element[] => {
      let jsx: JSX.Element[] = []

      let location: string;
      if(typeof window !== 'undefined') {
         location = window.location.pathname;
      }

      Object.keys(options).forEach(key => {
         if(options[key] !== location) {
            jsx.push(
               <a className="related-page-link" href={options[key]}>{key}</a>
            )
         }
      })

      return jsx;
   }

   return (
      <div className="related-pages-wrapper">
         <label htmlFor="pages-accordion-trigger">
            <h3 className={showPages ? 'show': 'hide'}>{title === undefined ? 'Related pages' : title}</h3>
         </label>

         <input
            id="pages-accordion-trigger"
            type="checkbox"
            className="pages-accordion-trigger invisible"
            onChange={(e) => setShowPages(e.target.checked)}
            checked={showPages}
         />

         <div className={`pages-link-list ${showPages ? 'expand': 'hide'}`}>
            {generateOptions()}
         </div>
      </div>
   )
}

export default RelatedPages