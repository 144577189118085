import { HeadFC } from "gatsby";
import React, { useEffect, useState } from "react";
import CookiesConsentNotice from "../components/cookies-consent/cookies-consent";
import Footer from "../components/footer/footer";
import Navigation from "../components/navigation/navigation";
import SEO from "../components/seo/seo";
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import ReCAPTCHA from "react-google-recaptcha";
import ErrorMessage from "../components/error-message/error-message";
import RelatedPages from "../components/related-pages/related-pages";
import axiosRetry from "axios-retry";
import axios from "axios";

// CSS
import "../sass/page-specific/policy-styles.scss";
import StylesComponent from "../components/styles/styles-component";
import { trackPromise } from "react-promise-tracker";
import FullScreenPromiseTracker from "../components/promise-tracker/full-screen-tracker";

axiosRetry(axios, {
   retries: 3,
   retryDelay: (retryCount) => {
      console.log(`Error - retry attempt: ${retryCount}`)
      return retryCount * 500
   },
   retryCondition: (err) => {
      return err.response?.status === 504
   }
})

interface OptOuts {
   email_optout: boolean,
   email_address: string,
   phone_optout: boolean,
   phone_number: string
}

interface ErrorTypes {
   email_address: boolean,
   email_already: boolean,
   phone_number: boolean,
   phone_already: boolean,
   at_least_one: boolean,
   recaptcha: boolean,
   error: boolean
}

const CommunicationPreferences: React.FC = (): JSX.Element => {
   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])

   const [optoutData, setOptoutData] = useState<OptOuts>({
      email_optout: false,
      email_address: "",
      phone_optout: false,
      phone_number: ""
   })

   const [errors, setErrors] = useState<ErrorTypes>({
      email_address: false,
      email_already: false,
      phone_number: false,
      phone_already: false,
      at_least_one: false,
      recaptcha: false,
      error: false
   })

   const [errorMessage, setErrorMessage] = useState<string>("")

   const [reCaptchaFootprint, setReCaptchaFootprint] = useState<string | null>(null)

   const [optoutSubmitted, setOptoutSubmitted] = useState<boolean>(false)

   const handleUpdateForm = (e: React.ChangeEvent<HTMLInputElement>): void => {
      const { value, name, checked, type } = e.target;

      setOptoutData({
         ...optoutData,
         [name]: type === 'checkbox' ? checked : value
      })

      let update_object = { [name]: false }

      if(name === "email_optout") update_object.email_address = false;
      if(name === "phone_optout") update_object.phone_number = false;
      if(name === "email_address") update_object.email_already = false;
      if(name === "phone_number") update_object.phone_already = false;

      setErrors({
         ...errors,
         ...update_object,
         at_least_one: false,
         error: false
      })
   }

   const handleDataValidation = (): boolean => {
      let errors_count: number = 0;
      let errors_object: { [key: string]: boolean } = {};

      if(optoutData.email_optout === false && optoutData.phone_optout === false) {
         errors_count++;
         errors_object.at_least_one = true;
      } else {
         // Email address
         if(optoutData.email_optout === true) {
            if(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(optoutData.email_address) === false) {
               errors_count++;
               errors_object.email_address = true;
            }
         }

         // Phone number
         if(optoutData.phone_optout === true) {
            if(/^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/.test(optoutData.phone_number) === false) {
               errors_count++;
               errors_object.phone_number = true;
            }
         }

         if(errors_count === 0) {
            if(reCaptchaFootprint === null) {
               errors_count++;
               errors_object.recaptcha = true;
            }
         }
      }
      
      if(errors_count === 0) {
         return true
      } else {
         setErrors({
            ...errors,
            ...errors_object
         })

         return false;
      }
   }

   const submitOptOut = async (): Promise<void> => {
      if(handleDataValidation() === true) {
         trackPromise(
            new Promise<void>( async (resolve) => {
               await axios({
                  method: 'put',
                  url: "https://api.prestige-vip.com/web/marketing/preferences/optout",
                  headers: {
                     Authorization: "Bearer " + process.env.GATSBY_SERVER_TOKEN
                  },
                  data: {
                     ...optoutData,
                     recaptcha_footprint: reCaptchaFootprint
                  }
               })
               .then((value) => {
                  const response = value.data;
                  let errors_object: { [key: string]: boolean } = {};

                  // Get individual errors
                  if(optoutData.email_optout === true) {
                     if(response.data.email.success === false && response.data.email.reason.includes("already")) {
                        errors_object.email_already = true
                     } else {
                        errors_object.email_already = false
                     }
                  }

                  if(optoutData.phone_optout === true) {
                     if(response.data.phone.success === false && response.data.phone.reason.includes("already")) {
                        errors_object.phone_already = true
                     } else {
                        errors_object.phone_already = false
                     }
                  }

                  if(response.success === true) {
                     setTimeout(() => {
                        setErrors({
                           ...errors,
                           ...errors_object
                        })

                        setOptoutSubmitted(true)
                        resolve()
                     }, 1000)
                  } else {
                     if(response.reason !== undefined) {
                        setErrors({
                           ...errors,
                           ...errors_object,
                           error: true
                        })

                        setErrorMessage(response.reason)
                     } else {
                        setErrors({
                           ...errors,
                           ...errors_object
                        })
                     }

                     resolve()
                  }
               })
               .catch(() => {
                  setErrors({
                     ...errors,
                     error: true
                  })

                  setErrorMessage("Ooops, there was a technical error, please try again")
                  resolve()
               })
            })
         , 'submit_optout')
      }
   }

   return (
      <React.Fragment>
         <StylesComponent/>
         <Navigation/>
         <CookiesConsentNotice/>

         <div className="policy-hero-outer-container">
            <div className="policy-hero-container-overlay">
               <div className="policy-hero-text-container container-width">
                  <h1>Communication preferences</h1>
                  <p className="secondary-text">Opt out of communications from Prestige VIP</p>
               </div>
            </div>
         </div>

         <div className="policy-content-outer-container">
            <div className="policy-content-grid-container container-width">
               <div className="main-content-container">
                  <Breadcrumbs location="communication-preferences"/>
                  <FullScreenPromiseTracker
                     searchArea="submit_optout"
                     message="Submitting opt-out"
                  />

                  {!optoutSubmitted ? (
                     <React.Fragment>
                        <h2>Stop receiving communications</h2>

                        <p>Use this page to customise your contact preferences by opting out of receiving communications by email or telephone. If you opt out of email communications you will automatically be opted-out of our newsletter if you are enrolled. <i>Please note, if you are a customer that has an active contract with us, we may still need to contact you even after your opt-out for limited reasons related to your booking.</i></p>

                        <br/>

                        <div style={{marginTop: 20}} className="terms-agreement-wrapper">
                           <input
                              className="terms-agreement-input invisible"
                              type="checkbox"
                              id="email_optout"
                              name="email_optout"
                              checked={optoutData.email_optout}
                              onChange={(e) => handleUpdateForm(e)}
                           />

                           <label className="terms-agreement-checkbox negative" htmlFor="email_optout"/>

                           <p>Opt out of <u>email</u> communications</p>
                        </div>

                        {
                           optoutData.email_optout ? (
                              <React.Fragment>
                                 <hr style={{marginTop: 20}} />

                                 <p className="secondary-text dark"><i>Please enter the email address that you would like us to stop making communication with, including being removed from the mailing list if present.</i></p>

                                 <label style={{marginTop: 20}} className={`expanded-input-wrapper email restrict-width plain-white ${errors.email_address ? 'error' : ''}`} htmlFor="email_address">
                                    <div className="expanded-input-content">
                                       <input
                                          id="email_address"
                                          className="expanded-input"
                                          placeholder="e.g. john.smith@gmail.com"
                                          autoComplete="off"
                                          name="email_address"
                                          value={optoutData.email_address}
                                          onChange={(e) => handleUpdateForm(e)}
                                       />

                                       <label className="expanded-input-label" htmlFor="email_address">Email address to opt out*</label>
                                    </div>
                                 </label>
                              </React.Fragment>
                           ) : null
                        }

                        {
                           errors.email_address ? (
                              <ErrorMessage
                                 message="Please enter a valid email address"
                                 bottomSpacing={0}
                              />
                           ) : null
                        }

                        {
                           errors.email_already ? (
                              <ErrorMessage
                                 type="box"
                                 message="This email address has already been opted-out and does not receive email communications from Prestige VIP."
                                 bottomSpacing={0}
                              />
                           ) : null
                        }

                        <br/>

                        <div style={{marginTop: 20}} className="terms-agreement-wrapper">
                           <input
                              className="terms-agreement-input invisible"
                              type="checkbox"
                              id="phone_optout"
                              name="phone_optout"
                              checked={optoutData.phone_optout}
                              onChange={(e) => handleUpdateForm(e)}
                           />

                           <label className="terms-agreement-checkbox negative" htmlFor="phone_optout"/>

                           <p>Opt out of <u>telephone</u> communications</p>
                        </div>

                        {
                           optoutData.phone_optout ? (
                              <React.Fragment>
                                 <hr style={{marginTop: 20}} />

                                 <p className="secondary-text dark"><i>Please enter the phone number that you would like us to stop making communication with.</i></p>
                                 <label style={{marginTop: 20}} className={`expanded-input-wrapper phone restrict-width plain-white ${errors.phone_number ? 'error' : ''}`} htmlFor="phone_number">
                                    <div className="expanded-input-content">
                                       <input
                                          id="phone_number"
                                          className="expanded-input"
                                          placeholder="e.g. 07444123456"
                                          autoComplete="off"
                                          name="phone_number"
                                          value={optoutData.phone_number}
                                          onChange={(e) => handleUpdateForm(e)}
                                       />

                                       <label className="expanded-input-label" htmlFor="phone_number">Phone number to opt out*</label>
                                    </div>
                                 </label>
                              </React.Fragment>
                           ) : null
                        }

                        {
                           errors.phone_number ? (
                              <ErrorMessage
                                 message="Please enter a valid UK phone number"
                                 bottomSpacing={0}
                              />
                           ) : null
                        }

                        {
                           errors.phone_already ? (
                              <ErrorMessage
                                 type="box"
                                 message="This phone number has already been opted-out and does not receive phone communications from Prestige VIP."
                                 bottomSpacing={0}
                              />
                           ) : null
                        }

                        {
                           errors.at_least_one ? (
                              <ErrorMessage
                                 message="Please choose at least one option to opt out"
                                 topSpacing={25}
                                 bottomSpacing={-10}
                              />
                           ) : null
                        }

                        <br/>

                        <p><i>Please note that it may take up to 24 hours for your details to be opted out of our system. If you would like to discuss a privacy concern, please email us at privacy@prestige-vip.com</i></p>
                        
                        <br/>

                        <ReCAPTCHA
                           sitekey={process.env.GATSBY_RECAPTCHA_KEY}
                           onChange={e => {
                              setReCaptchaFootprint(e)
                              setErrors({
                                 ...errors,
                                 recaptcha: false
                              })
                           }}
                           onError={e => console.error(e)}
                        />

                        {
                           errors.recaptcha ? (
                              <ErrorMessage
                                 message="Please prove that you are not a robot"
                              />
                           ) : null
                        }

                        {
                           errors.error && errorMessage !== "" ? (
                              <ErrorMessage
                                 message={errorMessage}
                              />
                           ) : null
                        }

                        <br/>

                        <button
                           className="standard-button orange"
                           onClick={submitOptOut}
                        >Submit opt-out</button>
                     </React.Fragment>
                  ) : (
                     <React.Fragment>
                        <h2>You have been opted out</h2>

                        <p className="secondary-text dark">Your opt-out has now been processed and you will stop receiving communications from Prestige VIP as requested.</p>

                        {
                           errors.email_already ? (
                              <ErrorMessage
                                 type="box"
                                 message={`The email address ${optoutData.email_address} showed on our records as already being opted-out. We have thus not taken any action for your email address. However, your phone number has been removed from our call list.`}
                              />
                           ) : null
                        }

                        {
                           errors.phone_already ? (
                              <ErrorMessage
                                 type="box"
                                 message={`The phone number ${optoutData.phone_number} showed on our records as already being opted-out. We have thus not taken any action for your phone number. However, your email address has been removed from our mailing list.`}
                              />
                           ) : null
                        }

                        <br/>

                        <a href="/" className="standard-button orange">Back to homepage</a>
                     </React.Fragment>
                  )}
               </div>

               <div className="content-sidebar-container">
                  <div className="sidebar-cta-wrapper">
                     <RelatedPages
                        title="Important documents"
                        options={{
                           "Privacy policy": "/privacy-policy",
                           "Terms & conditions": "/terms-conditions"
                        }}
                     />
                  </div>
               </div>
            </div>
         </div>

         <Footer/>
      </React.Fragment>
   )
}

export default CommunicationPreferences

export const Head: HeadFC = () => (
   <SEO
      title="Communication Preferences | Prestige VIP"
      metaDescription="Choose your communication preferences, opt in or out of marketing calls, emails and more"
      slug="/communication-preferences"
   />
)